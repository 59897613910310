<template>
  <div class="row">
    <div v-for="(customField, index) in contactFields" :class="{ 'col-md-12': fullWidth, 'col-md-6': !fullWidth }"
      :key="index">
      <text-input v-if="customField.type === 2" v-model="iValue[customField.id]" :label="customField.name" :hide-bar="hideBar"
        :margin-bottom="marginBottom" @change="() => $emit('change')" />
      <BirthdayInput v-else-if="customField.type === 3" v-model="iValue[customField.id]" :label="customField.name" @input="() => $emit('change')"/>
      <textarea-input v-else v-model="iValue[customField.id]" :label="customField.name"
        :hide-bar="hideBar" :margin-bottom="marginBottom" :hide-length="true" :rows="3" @change="() => $emit('change')" />
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    value: {
      required: true,
    },
    marginBottom: {
      type: Number,
      default: undefined,
    },
    hideBar: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
    }
  },

  computed: {
    iValue: {
      get() { return this.value },
      set(v) {
        this.$emit('input', v)
      },
    },
    contactFields() {
      return this.$store.getters["auth/contactFields"];
    },
  },

  mounted() {
  },

  methods: {
  }
}
</script>
  